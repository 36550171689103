<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12" id="printContent">
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th>SL</th>
                                <th>Pharm. Id</th>
                                <th>Pharm. Name</th>
                                <th>Shop Name</th>
                                <th>Phone</th>
                                <th>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(pharma, ind) in $store.getters['pharmacist/pharmacists']" :key="ind">
                                <td>{{ind + 1 }}</td>
                                <td>{{ pharma.code }}</td>
                                <td>{{ pharma.name }}</td>
                                <td>{{ pharma.shop_name }}</td>
                                <td>{{ pharma.phone }}</td>
                                <td>{{ pharma.address }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>

            <v-col cols="12">
                <v-data-table
                    class="custom-data-table elevation-1"
					dense
					:headers="pharmacistHeaders"
					:loading="$store.getters['pharmacist/loading']"
					:items="$store.getters['pharmacist/pharmacists']"
					:items-per-page="10"
					:search="pharmacishSearch"
				>
					<template v-slot:top>
						<v-toolbar dense color="white" :elevation="1" height="34px" class="custom-toolbar mb-2">
							<v-btn small color="blue-grey lighten-4" @click.prevent="print">Print</v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-toolbar-title class="subtitle-2">Pharmacist List</v-toolbar-title>
							<v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form"> 
                                <v-text-field v-model="pharmacishSearch" dense single-line hide-details outlined placeholder="Search Month" autocomplete="off" class="table-search">
                                    <v-icon slot="append">mdi-magnify</v-icon>
                                </v-text-field>
                            </v-form>
                            <v-spacer></v-spacer>
                            <pharma-dialog :icon="true" ref="pharmaDialog"></pharma-dialog>
						</v-toolbar>
					</template>
					<template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editPharmacist(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="pharmacistIdToDelete = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
				</v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deletePharmacist"></confirm-dialog>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";
import pharmaDialog from "./../../components/Pharmacist";

export default {
    name: 'pharmacist',
    components: {
		"confirm-dialog": confirmDialog,
		"pharma-dialog": pharmaDialog,
    },

    data: ()=> ({
        pharmacistHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Phar. Id', value: 'code' },
            { text: 'Phar. Name', value: 'name' },
            { text: 'Shop', value: 'shop' },
            { text: 'Phone', value: 'phone' },
            { text: 'Address', value: 'address' },
            { text: 'Action ', value: 'action' },
        ],
        pharmacishSearch: '',
        pharmacistIdToDelete: null,
    }),

    created() {
        this.getPharmacists();
        this.setStyle();
        this.getCompanyProfile();
    },

    methods: {
        getCompanyProfile() {
            this.$store.dispatch('companyProfile/getCompanyProfile')
        },

        getPharmacists() {
            this.$store.dispatch('pharmacist/getPharmacists')
        },

        async editPharmacist(pharmacist) {
           Object.keys(this.$refs.pharmaDialog.pharmacist).forEach(key => {
                this.$refs.pharmaDialog.pharmacist[key] = pharmacist[key];
            })

            this.$refs.pharmaDialog.showPharmacistDialog = true;
        },

        deletePharmacist() {
            this.$store.dispatch('pharmacist/deletePharmacist', { id: this.pharmacistIdToDelete });
            this.$refs.confirmDialog.dialog = false;
        },
        
        async print(){
            let invoiceContent = document.querySelector('#printContent').innerHTML;
            let printWindow = window.open('', 'PRINT', `width=${screen.width}, height=${screen.height}, left=0, top=0`);
            let ImagePath = this.apiBasePath +`/`+ this.$store.getters['companyProfile/company'].image;
            let companyName = this.$store.getters['companyProfile/company'].company_name;
            let address = this.$store.getters['companyProfile/company'].address;
            let contactUs = this.$store.getters['companyProfile/company'].contact_us;
            let email = this.$store.getters['companyProfile/company'].email;
            printWindow.document.write(`
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <meta http-equiv="X-UA-Compatible" content="ie=edge">
                    <title>Pharmacist List</title>
                    <style>
                    </style>
                </head>
                <body>
                    <div>
                        <div class="container">
                            <table style="width:100%;">
                                <thead>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-2">
                                                    <img src="`+ImagePath+`" alt="Logo" style="height:90px; width:90%;object-fit: contain;" />
                                                </div>
                                                <div class="col-xs-10">
                                                    <strong style="font-size:18px;">${companyName}</strong><br>
                                                    <p style="white-space:pre-line;margin:0px">Address : ${address}</p>
                                                    <p style="margin:0px">Mobile : ${contactUs}</p>
                                                    <p style="margin:0px">E-mail : ${email}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="invoice-title">
                                                    Pharmacist List
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="custom-row">
                                                <div class="col-xs-12">
                                                    ${invoiceContent}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <div style="width:100%;height:50px;">&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>    
                    
                </body>
                </html>
            `);
            
            let invoiceStyle = printWindow.document.createElement('style');
            invoiceStyle.innerHTML = this.style.innerHTML;
            printWindow.document.head.appendChild(invoiceStyle);
            printWindow.moveTo(0, 0);
            
            printWindow.focus();
            await new Promise(resolve => setTimeout(resolve, 1000));
            printWindow.print();
            printWindow.close();
        },

        setStyle(){
            this.style = document.createElement('style');
            this.style.innerHTML = `
                .custom-row {
                    width: 100%;
                    display: block;
                }
                .print-btn a{
                    background: #CFD8DC;
                    display: inline-block;
                    padding: 3px 13px;
                    border-radius: 5px;
                    color: #000 !important;
                }
                .print-btn a:hover {
                    background: #B0BEC5;
                }
                .invoice-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 15px;
                    margin-bottom: 15px;
                    padding: 5px;
                    border-top: 1px dotted #454545;
                    border-bottom: 1px dotted #454545;
                }
                .col-xs-12 {
                    width: 100%;
                }
                .col-xs-10 {
                    width: 80%;
                    float: left;
                }
                .col-xs-9 {
                    width: 70%;
                    float: left;
                }
                .col-xs-7 {
                    width: 60%;
                    float: left;
                }
                .col-xs-6 {
                    width: 50%;
                    float: left;
                }
                .col-xs-5 {
                    width: 40%;
                    float: left;
                }
                .col-xs-4 {
                    width: 35%;
                    float: left;
                }
                .col-xs-3 {
                    width: 30%;
                    float: left;
                }
                .col-xs-2 {
                    width: 20%;
                    float: left;
                }
                .b-text {
                    font-weight: 500;
                    font-size: 15px;
                }
                .normal-text {
                    font-size: 14px;
                    margin: 0px;
                }
                .invoice-details {
                    width: 100%;
                    border-collapse: collapse;
                    border:1px solid #ccc;
                }
                .invoice-details thead {
                    font-weight: 500;
                    text-align:center;
                }
                .invoice-details tbody td{
                    padding: 0px 5px;
                }
                .text-center {
                    text-align: center;
                }
                .text-right {
                    text-align: right;
                }
                .line {
                    border-bottom: 1px solid #ccc;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
                .paid-text {
                    padding:30px 0px;
                }
                .mt-60 {
                    margin-top: 60px;
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .ml-20 {
                    margin-left: 20px;
                }
                .ft-fiext {
                    position: fixed;
                    bottom: 0;
                }
                @media print {
                    .hide {
                        display: none
                    }
                    .v-data-table > .v-data-table__wrapper > table {
                        width: 100%;
                        border-spacing: 0;
                    }
                    .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
                        font-size: 13px;
                        padding: 0px 1px;
                        color: #000;
                        border: 1px solid #ccc;
                        text-align: center;
                        height: 24px !important;
                    }
                    .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
                        height: 20px !important;
                        padding: 0px 5px !important;
                        font-size: 12px !important;
                        border: 1px solid #ccc !important;
                    }
                }
                .cls {
                    clear: both;
                }
            `;
            document.head.appendChild(this.style);
        },
    }
}
</script>

<style lang="scss" scoped>
    #printContent {
        display: none;
    }
    @media print {
        #printContent {
            display: block;
        }
    }
</style>